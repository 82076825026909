/**
 * 
 */
export default class RestApi {
  auth = {
    login: (data) => $app.api.post([ 'admin/auth/login', ]).data(data),
    info: () => $app.api.get([ 'auth/info', ]),
  }
  
  users = {
    get: (id) => $app.api.get([ 'users*', id, ]),
    getOne: (id) => $app.api.get([ 'users*', id, ]), ////////
    getTractors: (id) => $app.api.get([ 'users*/tractors', id, ]),
    edit: (userId, data) => $app.api.patch([ 'users*', userId, ]).data(data),
    editPass: (id, data) => $app.api.patch([ 'users*/edit-password', id, ]).data(data),
  }

  files = {
    create: (data) => {
      const formData = new FormData()
      $n.each(data, (value, name) => {
        formData.append(name, value)
      })
      return $app.api.post([ 'files', ]).data(formData).config({ headers: { 'Content-Type': 'multipart/form-data', }, })
    },

  }

  passport = {

    delete: (id) => $app.api.delete([ 'udv/passports/udo*', id, ]),
    delete2: (id) => $app.api.delete([ 'udv/passports/vod*', id, ]),


  };

  chat = {
    get: (userId) => $app.api.get([ 'chat/users*', userId, ]),
    send: (data) => $app.api.post([ 'chats', ]).data(data),
  }

  document = {
    create: (data) => $app.api.post([ 'documents', ]).data(data),
    patchEdit: (id, data) => $app.api.patch([ 'documents*', id, ]).data(data),


  }
  tractors = {
    get: (id) => $app.api.get([ 'tractors*', id, ]),
    delete: (id) => $app.api.delete([ 'tractors/delete*', id, ]),

  }

  trailers = {

    delete: (id) => $app.api.delete([ 'trailers/delete*', id, ]),

  }


  orders = {
    get: () => $app.api.get([ 'all/orders', ]),
    getOne: (id) => $app.api.get([ 'orders*', id, ]),
    toDone: (id) => $app.api.post([ 'orders*/done', id, ]),
    toProgress: (id) => $app.api.post([ 'orders*/progress', id, ]),
    // toActive: (id) => $app.api.post([ 'orders*/active', id, ]),
  }
  privacypolicy= {
    get: () => $app.api.get([ 'privacypolicy', ]),
    edit: (id, data) => $app.api.patch([ 'privacypolicy*', id, ]).data(data),
  }
  faqs ={
    get: () => $app.api.get([ 'faqs', ]),
    send: (data) => $app.api.post([ 'faqs', ]).data(data),
    delete: (id) => $app.api.delete([ 'faqs*', id, ]),
    edit: (id, data) => $app.api.patch([ 'faqs*', id, ]).data(data),
  }
  supports = {
    get: () => $app.api.get([ 'supports', ]),
    getOne: (id) => $app.api.get([ 'supports*', id, ]),
    edit: (id, data) => $app.api.patch([ 'supports*', id, ]).data(data),
  }
}
