<template>
  <i v-if="!icon.src" :class="['n-icon', type, 'fa-'+icon, {'fa-pulse': pulse}]" v-on="$listeners"></i>
  <img v-else class="icon-img-n-icon" :src="icon.src" alt="">
</template>

<script>
import props from './props'

export default {
  name: 'NIcon',
  mixins: [ props, ],
  mounted() {},
}
</script>

<style scoped lang="scss">
.icon-img-n-icon {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.n-icon{
  width: 20px;
  height: 20px;
  margin-right: 5px !important;
}
  @import url('https://use.fontawesome.com/releases/v5.7.2/css/all.css');
  /*@import "~@fortawesome/fontawesome-free/scss/fontawesome";*/
</style>
