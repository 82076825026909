<template>
  <div class="component-page-title">
    <div class="content">
      <h1 slot="header">{{ $app.router.current().title }}</h1>
      <div class="locale">
        <!--        <n-select style="width: 50px" :data="locales" :value="$app.store.getter('app.locale')" @select="changeLocale" />-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: { type: String, default: '', },
  },
  data() {
    return {
      locales: [
        { title: 'ru', value: 'ru', },
        { title: 'kz', value: 'kz', },
        { title: 'en', value: 'en', },
      ],
    }
  },
  mounted() {
    // $app.store.mutation('app.locale', $app.store.getter('app.locale'))
  },
  methods: {
    changeLocale(value) {
      $app.store.mutation('app.locale', value)
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
.component-page-title {
  .content {
    position: relative;
    h1 {
      margin: 0;
      padding: 25px var(--n-layout-content-padding);
      font-weight: 400;
    }
    .locale {
      position: absolute;
      right: var(--n-layout-content-padding);
      top: var(--n-layout-content-padding);
    }
    .n-breadcrumbs {
      padding: 12px var(--n-layout-content-padding);
      background: #fff;
      border: solid #ececec;
      border-width: 1px 0;
    }
  }
}
</style>
