<template>
  <div>
    <div v-for="notify in notifies" :key="notify.id" class="n-notify" @click=" $app.ui.closeNotify(notify.id)">
      <div :class="notify.color">
        {{ notify.message }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NNotify',
  computed: {
    notifies() {
      return $app.store.state('ui.notifies')
    },
  },
}
</script>

<style lang="scss" scoped>
.n-notify {
  width: 100%;
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow-y: auto;

    .danger {
      padding: 35px 20px 35px 20px;
      background: rgb(236, 90, 90);
    }
    .success {
      padding: 35px 20px 35px 20px;
      background: #81bf81;
    }
}
</style>
