<template>
  <div class="component-sidebar">
    <div class="sidebar">
      <div class="sidebar-content">
        <div class="logo">Кабинет Kraft</div>
        <div class="other">
          <!--          <n-select :data="langs" value="Русский" inline />-->
        </div>
        <div class="avatar-wrapper" @click="showSlide = !showSlide">
          <div class="left-part">
            <div class="image-place"></div>
            <div class="name-place">Менеджер</div>
          </div>
          <div class="right-part">
            <div class="image-place">
              <img :src="arrow">
            </div>
          </div>
        </div>
        <div v-if="showSlide" class="slide-content show">
          <ul class="lists">
            <li v-for="(item, index) in profile" :key="index" class="list">
              <div class="nav-link" @click="$router.push({name: item.route})">
                <div class="image-place">
                  <img :src="item.icon.src">
                </div>
                <span class="link">{{ item.title }}</span>
              </div>
            </li>
          </ul>
        </div>
        <div class="navigation">
          <ul class="lists">
            <li v-for="(item, index) in menu" :key="index" class="list">
              <div class="nav-link" @click="$router.push({name: item.route})">
                <div class="image-place">
                  <img :src="item.icon.src">
                </div>
                <span class="link">{{ item.title }}</span>
              </div>
            </li>
            <!--            <li class="list">-->
            <!--              <div class="nav-link">-->
            <!--                <div class="image-place">-->
            <!--                  <img :src="imgCarriers">-->
            <!--                </div>-->
            <!--                <span class="link">Перевозчики</span>-->
            <!--              </div>-->
            <!--            </li>-->
            <!--            <li class="list">-->
            <!--              <div class="nav-link">-->
            <!--                <div class="image-place">-->
            <!--                  <img :src="imgContracts">-->
            <!--                </div>-->
            <!--                <span class="link">Договора</span>-->
            <!--              </div>-->
            <!--            </li>-->
            <!--            <li class="list">-->
            <!--              <div class="nav-link">-->
            <!--                <div class="image-place">-->
            <!--                  <img :src="imgChats">-->
            <!--                </div>-->
            <!--                <span class="link">Чаты</span>-->
            <!--              </div>-->
            <!--            </li>-->
            <!--            <li class="list">-->
            <!--              <div class="nav-link">-->
            <!--                <div class="image-place">-->
            <!--                  <img :src="imgSettings">-->
            <!--                </div>-->
            <!--                <span class="link">Настройки</span>-->
            <!--              </div>-->
            <!--            </li>-->
            <!--            <li class="list">-->
            <!--              <div class="nav-link">-->
            <!--                <div class="image-place">-->
            <!--                  <img :src="imgSupport">-->
            <!--                </div>-->
            <!--                <span class="link">Заявки в тех.поддержку</span>-->
            <!--              </div>-->
            <!--            </li>-->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import arrow from 'assets/show-arrow-icon.svg'
import imgSvg from 'assets/application-icon.svg'
import imgCustomers from 'assets/customers-icon.svg'
import imgCarriers from 'assets/carriers-icon.svg'
import imgContracts from 'assets/contracts-icon.svg'
import imgChats from 'assets/chats-icon.svg'
import imgSettings from 'assets/settings-icon.svg'
import imgSupport from 'assets/support-icon.svg'
import imgExit from 'assets/exit-icon.svg'
import imgProfile from 'assets/profile-icon.svg'

export default {
  name: 'PageSidebar',
  props: {
    menu: { type: Array, default: () => [], },
    profile: { type: Array, default: () => [], },
  },
  data() {
    return {
      arrow,
      imgSvg,
      imgCustomers,
      imgCarriers,
      imgContracts,
      imgChats,
      imgSettings,
      imgSupport,
      imgExit,
      imgProfile,
      showSlide: false,
      locales: [
        { title: 'ru', value: 'ru', },
        { title: 'kz', value: 'kz', },
        { title: 'en', value: 'en', },
      ],
    }
  },
  mounted() {
    // $app.store.mutation('app.locale', $app.store.getter('app.locale'))
  },
  methods: {
    changeLocale(value) {
      $app.store.mutation('app.locale', value)
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>

.component-sidebar {
  background: #2a2a2a;
  width: 25%;
  height: 100vh;
  position: fixed;

  .sidebar {

    .sidebar-content {
      margin-top: 6vh;
      margin-bottom: 50px;
      width: 90%;
      float: right;
      color: var(--text-color-op);

      .logo {
        font-size: 2em;
        line-height: 1;
        font-weight: 600;
      }

      .other {
        height: 40px;
        --n-input-width: 150px;
      }

      .avatar-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #444444;
        border-bottom: 1px solid #444444;
        padding: 15px 15px 15px 0;
        font-size: .9em;
        color: #999;

        .left-part {
          display: flex;

          .image-place {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            border: 1px solid #fff
          }

          .name-place {
            margin: 10px;
            font-size: 14px;
          }
        }

        .right-part {

        }


      }
    }

    .slide-content {
      background: #444444;
      border-radius: 0 0 0 5px;
      transition: opacity 0.4s ease;
      overflow: hidden;
      opacity: 0;


      &.show {
        opacity: 1;
      }

      .list {
        list-style: none;

        .nav-link {
          display: flex;
          align-items: center;
          text-decoration: none !important;
          padding: 12px 10px;
          border-radius: 5px 0 0 5px;

          .image-place {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            width: 25px;
          }

          .link {
            font-size: 15px;
            color: #999999;
            font-weight: 400;
            text-decoration: none;
          }
        }

        .nav-link:hover {
          text-decoration: none;
          outline: none;

          .link {
            color: #fff;
            outline: none;
          }
        }

        .nav-link:focus {
          outline: none;
          text-decoration: none;
        }

        a {
          text-decoration: none !important;
        }
      }
    }

    .navigation {
      padding: 15px 0 30px 0;

      .list {
        list-style: none;

        .nav-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          padding: 12px 10px;
          border-radius: 5px 0 0 5px;

          .image-place {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            width: 25px;
          }

          .link {
            font-size: 15px;
            color: #999999;
            font-weight: 400;
          }
        }

        .nav-link:hover {
          background-color: red;
          text-decoration: none;
          outline: none;

          .link {
            color: #fff;
          }
        }
      }
    }
  }
}

</style>
