export default [
  { path: '', component: () => import('layouts/App'), children: [
    { path: '', component: () => import('layouts/auth/Index'), children: [
      { path: '/', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
      { path: '/login', name: 'login', icon: 'genderless', component: () => import('pages/login/Index'), },
    ], },
    { path: '', component: () => import('layouts/main/Index'), children: [
      { path: '/applications', name: 'applications', title: 'Заказы',
        icon: 'genderless', component: () => import('pages/index/Index'), },
      { path: '/application/:id', name: 'application', title: 'Заказ № :id',
        icon: 'genderless', component: () => import('pages/order/Index'), },

      { path: '/chats', name: 'chats', title: 'Чаты',
        icon: 'genderless', component: () => import('pages/chats/Index'), },
      { path: '/chats/:id', name: 'specific-chat', title: 'Чат',
        icon: 'genderless', component: () => import('pages/specific-chat/Index'), },

      { path: '/profile', name: 'profile', title: 'Профиль',
        icon: 'genderless', component: () => import('pages/profile/Index'), },


      { path: '/customers', name: 'customers', title: 'Заказчики',
        icon: 'genderless', component: () => import('../pages/customers/Index'), },
      { path: '/customers/:id', name: 'customer-info', title: 'Данные Заказчика',
        icon: 'genderless', component: () => import('../pages/customer-info/Index'), },
      { path: '/customers/chat/:id', name: 'chat-customer-currier', title: 'Чаты с Заказчиком',
        icon: 'genderless', component: () => import('../pages/chat-customer-currier/Index'), },


      { path: '/curriers', name: 'curriers', title: 'Перевозчики',
        icon: 'genderless', component: () => import('../pages/curriers/Index'), },
      { path: '/curriers/:id', name: 'currier-info', title: 'Данные Перевозчика',
        icon: 'genderless', component: () => import('../pages/currier-info/Index'), },
      { path: '/currier/chat/:id', name: 'chat-currier-customer', title: 'Чаты с Перевозчиком',
        icon: 'genderless', component: () => import('../pages/chat-customer-currier/Index'), },

      { path: '/curriers/transport/:id', name: 'transport', title: 'Транспорт Перевозчика',
        icon: 'genderless', component: () => import('../pages/transport/Index'), },
      { path: '/curriers/transport-info/:id', name: 'transport-info', title: 'Транспорт Перевозчика',
        icon: 'genderless', component: () => import('../pages/transport-info/Index'), },
      { path: '/currier/chat/:id', name: 'chat-currier-customer', title: 'Чаты с Перевозчиком',
        icon: 'genderless', component: () => import('../pages/chat-customer-currier/Index'), },
      { path: '/order/chat/:id', name: 'currier-customer-manager-chat', title: 'Заказ №___',
        icon: 'genderless', component: () => import('../pages/currier-customer-manager-chat/Index'), },
      { path: '/contracts', name: 'contracts', title: 'Договора',
        icon: 'genderless', component: () => import('../pages/contracts/Index'), },
      { path: '/settings', name: 'settings', title: 'Настройки',
        icon: 'genderless', component: () => import('pages/settings/Index'), },
      { path: '/support', name: 'support', title: 'Заявки в тех. поддержку',
        icon: 'genderless', component: () => import('pages/support/Index'), },
      { path: '/support/:id', name: 'menager-chat', title: 'Заявки в тех. поддержку',
        icon: 'genderless', component: () => import('pages/menager-chat/Index'), },

    ], },
  ], },
]
