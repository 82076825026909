import Aside from 'nast-ui/components/Aside/default/Index.vue'
import AsideHamburger from 'nast-ui/components/Aside/_AsideHamburger/default/Index.vue'
import Breadcrumbs from 'nast-ui/components/Breadcrumbs/default/Index.vue'
import Button from 'nast-ui/components/Button/default/Index.vue'
import Card from 'nast-ui/components/Card/default/Index.vue'
import Center from 'nast-ui/components/Center/default/Index.vue'
import Container from 'nast-ui/components/Container/default/Index.vue'
import DataComponent from 'nast-ui/components/DataComponent/default/Index.vue'
import DataFilter from 'nast-ui/components/DataFilter/default/Index.vue'
import Datalist from 'nast-ui/components/Datalist/default/Index.vue'
import Datatable from 'nast-ui/components/Datatable/default/Index.vue'
import Divide from 'nast-ui/components/Divide/default/Index.vue'
import Dropdown from 'nast-ui/components/Dropdown/default/Index.vue'
import DropdownItem from 'nast-ui/components/Dropdown/_DropdownItem/default/Index.vue'
import DropdownGroup from 'nast-ui/components/Dropdown/_DropdownGroup/default/Index.vue'
import EditableFile from 'nast-ui/components/EditableFile/default/Index.vue'
import File from 'nast-ui/components/File/default/Index.vue'
import Form from 'nast-ui/components/Form/default/Index.vue'
import FormItem from 'nast-ui/components/FormItem/default/Index.vue'
import Header from 'nast-ui/components/Header/default/Index.vue'
import Icon from '_ui/components/n-icon/Index'
import Image from 'nast-ui/components/Image/default/Index.vue'
import Input from 'nast-ui/components/Input/default/Index.vue'
import Items from 'nast-ui/components/Items/default/Index.vue'
import Link from 'nast-ui/components/Link/default/Index.vue'
import List from 'nast-ui/components/List/default/Index.vue'
import ListGroup from 'nast-ui/components/List/_ListGroup/default/Index.vue'
import ListItem from 'nast-ui/components/List/_ListItem/default/Index.vue'
import Loader from 'nast-ui/components/Loader/default/Index.vue'
import Modal from 'nast-ui/components/Modal/default/Index.vue'
import ModalCard from 'nast-ui/components/ModalCard/default/Index.vue'
import Navigation from 'nast-ui/components/Navigation/default/Index.vue'
import NavigationGroup from 'nast-ui/components/Navigation/_NavigationGroup/default/Index.vue'
import NavigationItem from 'nast-ui/components/Navigation/_NavigationItem/default/Index.vue'
import Pagination from 'nast-ui/components/Pagination/default/Index.vue'
import Popup from 'nast-ui/components/Popup/default/Index.vue'
import Progress from 'nast-ui/components/Progress/default/Index.vue'
import Select from 'nast-ui/components/Select/default/Index.vue'
import Table from 'nast-ui/components/Table/default/Index.vue'
import Tabs from 'nast-ui/components/Tabs/default/Index.vue'
import TabsContent from 'nast-ui/components/Tabs/_TabsContent/default/Index.vue'
import Typography from 'nast-ui/components/Typography/default/Index.vue'
import Upload from 'nast-ui/components/Upload/default/Index.vue'
import Notify from '_ui/components/notify/Index'

export default {
  install(Vue) {
    Vue.component('NAside', Aside)
    Vue.component('NAsideHamburger', AsideHamburger)
    Vue.component('NBreadcrumbs', Breadcrumbs)
    Vue.component('NButton', Button)
    Vue.component('NCard', Card)
    Vue.component('NCenter', Center)
    Vue.component('NContainer', Container)
    Vue.component('NDataComponent', DataComponent)
    Vue.component('NDataFilter', DataFilter)
    Vue.component('NDatalist', Datalist)
    Vue.component('NDatatable', Datatable)
    Vue.component('NDivide', Divide)
    Vue.component('NDropdown', Dropdown)
    Vue.component('NDropdownItem', DropdownItem)
    Vue.component('NDropdownGroup', DropdownGroup)
    Vue.component('NEditableFile', EditableFile)
    Vue.component('NFile', File)
    Vue.component('NForm', Form)
    Vue.component('NFormItem', FormItem)
    Vue.component('NHeader', Header)
    Vue.component('NIcon', Icon)
    Vue.component('NImage', Image)
    Vue.component('NInput', Input)
    Vue.component('NItems', Items)
    Vue.component('NLink', Link)
    Vue.component('NList', List)
    Vue.component('NListGroup', ListGroup)
    Vue.component('NListItem', ListItem)
    Vue.component('NLoader', Loader)
    Vue.component('NModal', Modal)
    Vue.component('NModalCard', ModalCard)
    Vue.component('NNavigation', Navigation)
    Vue.component('NNavigationGroup', NavigationGroup)
    Vue.component('NNavigationItem', NavigationItem)
    Vue.component('NPagination', Pagination)
    Vue.component('NPopup', Popup)
    Vue.component('NProgress', Progress)
    Vue.component('NSelect', Select)
    Vue.component('NTable', Table)
    Vue.component('NTabs', Tabs)
    Vue.component('NTabsContent', TabsContent)
    Vue.component('NTypography', Typography)
    Vue.component('NUpload', Upload)
    Vue.component('NNotify', Notify)
  },
}
